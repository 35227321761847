import gsap from "gsap";

export default class MainNav {
  constructor() {
    this.menuOpen = false;
    this.altMenuOpen = false;
    this.subMenuOpen = null;

    document.addEventListener("components_init", () => {
      this.init();
    });
  }

  isMobile() {
    return window.innerWidth < 1024;
  }

  isTouch() {
    return "ontouchstart" in document.documentElement;
  }

  getToggle() {
    return document.querySelector(".main-nav__toggle-container");
  }

  getMenu() {
    return document.querySelector(".main-nav__menu");
  }

  getAltMenu() {
    return document.querySelector(".main-nav__alt-menu");
  }

  getItemsWithSubmenu() {
    return document.querySelectorAll(".main-nav__menu-item--has-submenu");
  }

  getLinksWithSubmenu() {
    return document.querySelectorAll(".main-nav__menu-item--has-submenu > a");
  }

  getItems() {
    return document.querySelectorAll(".main-nav__menu-item");
  }

  init() {
    const toggle = this.getToggle();

    if (!toggle) {
      return;
    }

    this.scroll();

    toggle.onclick = () => {
      this.toggle();
    };

    const li = this.getItemsWithSubmenu();
    const links = this.getLinksWithSubmenu();
    const items = this.getItems();

    if (this.isTouch()) {
      li.forEach((element) => {
        element.onclick = (e) => {
          e.stopPropagation();
          this.toggleSubMenu(element);
        };
      });

      links.forEach((element) => {
        element.onclick = (e) => {
          e.preventDefault();
        };
      });
    }

    if (!this.isTouch()) {
      li.forEach((element) => {
        element.onmouseenter = () => {
          this.openSubMenu(element);
        };

        element.onmouseleave = () => {
          this.closeSubMenu(element);
        };
      });

      links.forEach((element) => {
        element.onfocus = () => {
          this.openSubMenu(element);
        };

        element.onblur = () => {
          this.closeSubMenu(element);
        };
      });

      items.forEach((element) => {
        const a = element.querySelector(":scope > a");

        a.onmouseenter = () => {
          element.classList.add("main-nav__menu-item--highlighted");
        };

        a.onmouseleave = () => {
          element.classList.remove("main-nav__menu-item--highlighted");
        };

        a.onfocus = () => {
          element.classList.add("main-nav__menu-item--highlighted");
        };

        a.onblur = () => {
          element.classList.remove("main-nav__menu-item--highlighted");
        };
      });
    }

    window.onresize = () => {
      this.reset();
    };
  }

  toggle() {
    if (this.isMobile()) {
     this.toggleMenu();
    } else {
     this.toggleAltMenu();
    }
  }

  toggleMenu() {
    if (this.menuOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }

    this.menuOpen = !this.menuOpen;
  }

  toggleAltMenu() {
    if (this.altMenuOpen) {
      this.closeAltMenu();
    } else {
      this.openAltMenu();
    }

    this.altMenuOpen = !this.altMenuOpen;
  }

  openMenu() {
    const menu = this.getMenu();
    const toggle = this.getToggle();

    gsap.fromTo(
      menu,
      {
        height: "0px",
        display: "block",
        duration: 0.5,
      },
      {
        height: "auto",
        display: "block",
        duration: 0.5,
      }
    );

    toggle.classList.add("main-nav__toggle--active");
  }

  closeMenu() {
    const menu = this.getMenu();
    const toggle = this.getToggle();

    gsap.fromTo(
      menu,
      {
        height: "auto",
        display: "block",
        duration: 0.5,
      },
      {
        height: "0px",
        display: "block",
        duration: 0.5,
      }
    );

    toggle.classList.remove("main-nav__toggle--active");
  }

  openAltMenu() {
    const menu = this.getAltMenu();
    const toggle = this.getToggle();

    gsap.fromTo(
      menu,
      {
        opacity: 0,
        display: "flex",
        duration: 0.5,
      },
      {
        opacity: 1,
        display: "flex",
        duration: 0.5,
      }
    );

    toggle.classList.add("main-nav__toggle--active");
  }

  closeAltMenu() {
    const menu = this.getAltMenu();
    const toggle = this.getToggle();

    gsap.fromTo(
      menu,
      {
        opacity: 1,
        display: "flex",
        duration: 0.5,
      },
      {
        opacity: 0,
        display: "none",
        duration: 0.5,
      }
    );

    toggle.classList.remove("main-nav__toggle--active");
  }

  toggleSubMenu(element) {
    if (this.subMenuOpen !== null) {
      //whether is open
      if (element.classList.contains("main-nav__menu-item--submenu-open")) {
        const current = element;

        this.closeSubMenu(current);
        if (element.classList.contains("main-nav__menu-item")) {
          //close all submenus
          // get all items which have main-nav__menu-item--submenu-open
          const items = document.querySelectorAll(
            ".main-nav__menu-item--has-submenu"
          );
          items.forEach((element) => {
            element.classList.remove("main-nav__menu-item--submenu-open");
          });
          const thirdLvlItems = document.querySelectorAll(
            ".main-nav__menu-item--has-thirdmenu"
          );
          thirdLvlItems.forEach((element) => {
            element.style.display = "none";
          });
        }

        if (current == element) {
          return;
        }
      }
    }
    this.openSubMenu(element);
  }

  openSubMenu(element) {
    const menu = element.querySelector("ul");
    this.subMenuOpen = element;
    element.classList.add("main-nav__menu-item--submenu-open");

    if (this.isMobile()) {
      gsap.fromTo(
        menu,
        {
          height: "0px",
          display: "block",
          duration: 0.5,
        },
        {
          height: "auto",
          display: "block",
          position: "relative",
          width: "100%",
          duration: 0.5,
        }
      );
    } else {
      gsap.fromTo(
        menu,
        {
          paddingTop: "20px",
          opacity: 0,
          display: "block",
          duration: 0.5,
        },
        {
          paddingTop: "0px",
          opacity: 1,
          display: "block",
          duration: 0.5,
        }
      );
    }
  }

  closeSubMenu(element) {
    if (this.subMenuOpen === null) {
      return;
    }

    const menu = element.querySelector("ul");

    if (element.classList.contains("main-nav__menu-item")) {
      this.subMenuOpen = null;
    }

    element.classList.remove("main-nav__menu-item--submenu-open");

    if (this.isMobile()) {
      gsap.fromTo(
        menu,
        {
          height: "auto",
          display: "block",
          duration: 0.5,
        },
        {
          height: 0,
          display: "none",
          duration: 0.5,
        }
      );
    }

    if (!this.isMobile()) {
      gsap.fromTo(
        menu,
        {
          paddingTop: "0px",
          opacity: 1,
          display: "block",
          duration: 0.5,
        },
        {
          paddingTop: "20px",
          opacity: 0,
          display: "none",
          duration: 0.5,
        }
      );
    }
  }

  reset() {
    if (this.subMenuOpen !== null) {
      const menu = this.subMenuOpen.querySelector("ul");
      gsap.set(menu, { clearProps: "all" });

      this.subMenuOpen.classList.remove("main-nav__menu-item--submenu-open");
      this.subMenuOpen = null;
    }

    const menu = this.getMenu();
    const altMenu = this.getAltMenu();
    const toggle = this.getToggle();

    gsap.set(menu, { clearProps: "all" });
    gsap.set(altMenu, { clearProps: "all" });

    this.menuOpen = false;
    this.altMenuOpen = false;

    toggle.classList.remove("main-nav__toggle--active");
  }

  scroll() {
    const nav = document.querySelector(".main-nav__upper");
    (nav && window.addEventListener("scroll", function () {
      const scrolled = document.scrollingElement.scrollTop;
      const position = nav.offsetTop;
      if (scrolled > position) {
        nav.style.opacity = 0.85;
      } else {
        nav.style.opacity = 1;
      }
    }));
  }
}
