import gsap from 'gsap';

export default class PortalModal {

	constructor() {
		this.panel = null;
	}
	
	getModal(mainPopupId) {
		return document.querySelector(`#${mainPopupId}`);
	}
	
	openModal(mainPopupId, popupId) {
		
		const modal = this.getModal(mainPopupId);
		if (!modal) {
			return;
		}
		this.panel = modal.querySelector(`#${popupId}`);
		
		gsap.fromTo(modal, {
			opacity: 0,
			display: 'none',
			duration: 0.25
		}, {
			opacity: 1,
			display: 'flex',
			duration: 0.25
		});
		
		gsap.fromTo(this.panel, {
			scale: 0,
			display: 'none',
			duration: 0.25
		}, {
			scale: 1,
			display: 'block',
			duration: 0.25
		});
		
	}

	closeModal(mainPopupId) {
		const modal = this.getModal(mainPopupId);

		gsap.fromTo(modal, {
			opacity: 1,
			display: 'flex',
			duration: 0.25
		}, {
			opacity: 0,
			display: 'none',
			duration: 0.25
		});

		gsap.fromTo(this.panel, {
			scale: 1,
			display: 'block',
			duration: 0.25
		}, {
			scale: 0,
			display: 'none',
			duration: 0.25
		});
		
		this.panel = null;
		
		this.hidePopupError(mainPopupId)
	}

	showPopupError(mainPopupId)
	{
		const modal = this.getModal(mainPopupId);
		if (!modal) {
			return;
		}

		const errDiv = modal.querySelector("#divPopupError");
		if (!errDiv) {
			return;
		}
		
		errDiv.style.display = "block";
	}


	hidePopupError(mainPopupId)
	{
		const modal = this.getModal(mainPopupId);
		if (!modal) {
			return;
		}

		const errDiv = modal.querySelector("#divPopupError");
		if (!errDiv) {
			return;
		}
		
		errDiv.style.display = "none";

	}

	scrollToTop() {
		window.scrollTo({top: 0, behavior: 'smooth'});
	}

}