import gsap from "gsap";

export default class LoadingAnimation {
  constructor() {
    document.addEventListener("components_init", () => {
      this.animate();
    });
  }

  animate() {
    const fireworks = document.querySelectorAll(
      ".loading-animation-standard__svg--firework, .loading-animation-standard__svg--firework1, .loading-animation-standard__svg--firework2, .loading-animation-standard__svg--firework3"
    );
    const stars = document.querySelectorAll(
      ".loading-animation-standard__svg--star, .loading-animation-standard__svg--star1, .loading-animation-standard__svg--star2, .loading-animation-standard__svg--star3, .loading-animation-standard__svg--star4"
    );
    const cross = document.querySelectorAll(
      ".loading-animation-standard__svg--cross, .loading-animation-standard__svg--cross1, .loading-animation-standard__svg--cross4"
    );
    const crossTwo = document.querySelectorAll(
      ".loading-animation-standard__svg--cross2, .loading-animation-standard__svg--cross3"
    );

    const rotatingStar = document.querySelectorAll(".loading-animation-standard__svg--starRotate");

    const tl = gsap.timeline({ yoyo: true, repeatDelay: 1, repeat: -1 });
    tl.fromTo(
      fireworks,
      {
        scale: 0,
      },
      {
        scale: 1.4,
        yoyo: true,
        repeat: -1,
      }, "0.5"
    );
    tl.fromTo(
      stars,
      {
        scale: 1,
      },
      {
        scale: 1.4,
        yoyo: true,
        rotation: 90,
        repeat: -1,
        
      }, "+=1"
    );
     tl.fromTo(
      crossTwo,
      {
        rotation: 0,
        scale: 0,
        transformOrigin: "50% 50%"
      },
      {
        rotation: 90,
        scale: 2,
        yoyo: true,
        transformOrigin: "50% 50%",
        repeat: -1,
      }
    ), "-=0.5";

     tl.fromTo(
      cross,
      {
        rotation: 0,
        transformOrigin: "50% 50%"
      },
      {
        rotation: 90,
        yoyo: true,
        transformOrigin: "50% 50%",
        repeat: -1,
      },  "-=0.5"
    );
    tl.to(
      rotatingStar,
      {
        rotation: 360,
        repeat: -1
      }
    )
  }
}
