import gsap from "gsap";

export default class PopupMessage {
  constructor() {
    document.addEventListener("components_init", () => {
      this.init();
    });
  }

  getElements() {
    this.elements = {
      info: document.querySelector("#popup-message"),
    };
  }

  init() {
    this.getElements();
  }

  showInfoModal() {
    gsap.fromTo(
      this.elements.info,
      {
        scale: 0,
        display: "none",
        duration: 0.25,
      },
      {
        scale: 1,
        display: "flex",
        duration: 0.25,
      }
    );
  }
  closeInfoModal() {
    gsap.fromTo(
      this.elements.info,
      {
        scale: 1,
        display: "flex",
        duration: 0.25,
      },
      {
        scale: 0,
        display: "none",
        duration: 0.25,
      }
    );
  }
}
