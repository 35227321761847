import gsap from 'gsap';

export default class CardsList {

	animate() {
		const rows = document.querySelectorAll('.cards-list__row');
		const bars = document.querySelectorAll('.cards-list__row-bar');
		
		gsap.from(rows, {
			y: 20,
			opacity: 0,
			duration: 1,
			stagger: 0.1
		});
		
		bars.forEach((bar) => {
			const inner = bar.querySelector('div');
			
			gsap.to(inner, {
				width: `${bar.dataset.value}%`,
				duration: 1,
				stagger: 0.1
			});
		});
	}

}